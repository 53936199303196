<template>
  <Layout header-class="header-left-align header-transparent" nav-alignment="left" :show-top-header="false" :footer-style="2" :show-footer="false">
    <Breadcrumb current-url="Privacy Policy" :title="title"/>

    <!-- Start Accordion Area -->
    <div class="rn-section-gap">
      <div class="container">
        <div class="row row--40 align-items-center">
          <div class="col-lg-8 offset-lg-2 mt_md--40 mt_sm--40">
            <div class="privacy-policy">
              <h4 class="title">Privacy Policy</h4>
              <p>
                We take the protection of your personal data very seriously. In this privacy policy, we inform you about what data we collect, how we use it, and what rights you have regarding your data.
              </p>
              <h6>1. Collection and Use of Data</h6>
              <p>
                We do not store any personal data on our website unless you voluntarily provide it to us via the contact form.
              </p>
              <h6>2. Google Maps</h6>
              <p>
                We use Google Maps on our website to display interactive maps. By using Google Maps, information about your use of this website (including your IP address) may be transmitted to and stored by Google.
              </p>
              <h6>3. Contact Form</h6>
              <p>
                If you send us a message through the contact form, we will store the information you provide, including your name, email address, and message. This data will only be used to process your inquiry and will not be shared with third parties.
              </p>
              <h6>4. Your Rights</h6>
              <p>
                You have the right to request information about the data we store, to correct, delete, or restrict the processing of your data. Furthermore, you can object to the processing of your data or revoke any consent you have given at any time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Accordion Area -->

    <Separator/>
  </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import Breadcrumb from '../../components/elements/breadcrumb/Breadcrumb'
import Separator from '../../components/elements/separator/Separator'
import Icon from '../../components/icon/Icon'

export default {
  name: 'PrivacyPolicyPage',
  components: {Icon, Separator, Breadcrumb, Layout},
  props: {
    title: {
      type: String,
      default: 'Privacy Policy'
    }
  },
}
</script>