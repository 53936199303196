<template>
  <Layout header-class="header-left-align header-transparent" nav-alignment="left" :show-top-header="false" :footer-style="2" :show-footer="false">
    <Breadcrumb current-url="Terms and Conditions" :title="title"/>

    <!-- Start Accordion Area -->
    <div class="rn-section-gap">
      <div class="container">
        <div class="row row--40 align-items-center">
          <div class="col-lg-8 offset-lg-2 mt_md--40 mt_sm--40">
            <div class="terms-conditions">
              <h4 class="title">Terms and Conditions</h4>
              <p>
                Please read these terms and conditions carefully before using our services.
              </p>
              <h6>1. Acceptance of Terms</h6>
              <p>
                By accessing or using our services, you agree to be bound by these terms and conditions.
              </p>
              <h6>2. Intellectual Property Rights</h6>
              <p>
                All intellectual property rights related to our services are the property of Chantal Herzog.
              </p>
              <h6>3. Limitation of Liability</h6>
              <p>
                We shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages.
              </p>
              <h6>4. Governing Law</h6>
              <p>
                These terms and conditions shall be governed by and construed in accordance with the laws of Germany.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Accordion Area -->

    <Separator/>
  </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import Breadcrumb from '../../components/elements/breadcrumb/Breadcrumb'
import Separator from '../../components/elements/separator/Separator'
import Icon from '../../components/icon/Icon'

export default {
  name: 'TermsConditionsPage',
  components: {Icon, Separator, Breadcrumb, Layout},
  props: {
    title: {
      type: String,
      default: 'Terms and Conditions'
    }
  },
}
</script>
