<template>
  <Layout header-class="header-left-align header-transparent" nav-alignment="left" :show-top-header="false" :footer-style="2" :show-footer="false">
    <Breadcrumb current-url="Cookie Policy" :title="title"/>

    <!-- Start Accordion Area -->
    <div class="rn-section-gap">
      <div class="container">
        <div class="row row--40 align-items-center">
          <div class="col-lg-8 offset-lg-2 mt_md--40 mt_sm--40">
            <div>
              <h4 class="title">Cookie Policy</h4>
              <p>
                We use cookies to enhance your browsing experience on our website. By continuing to use our website, you consent to the use of cookies in accordance with our <a href="#" class="cookie-link">Cookie Policy</a>.
              </p>
              <p>
                Our Cookie Policy explains what cookies are, how we use cookies, how third-parties we may partner with may use cookies on our website, your choices regarding cookies, and further information about cookies.
              </p>
              <ul class="liststyle bullet">
                <li>Essential Cookies: These cookies are necessary for the functioning of our website and cannot be switched off in our systems.</li>
                <li>Analytics Cookies: We use analytics cookies to collect information that helps us understand how visitors interact with our website.</li>
                <li>Marketing Cookies: Our marketing cookies allow us to show you relevant advertisements and measure the effectiveness of our marketing campaigns.</li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- End Accordion Area -->

    <Separator/>
  </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import Breadcrumb from '../../components/elements/breadcrumb/Breadcrumb'
import Separator from '../../components/elements/separator/Separator'
import Icon from '../../components/icon/Icon'

export default {
  name: 'ConceptDesignServiceDetails',
  components: {Icon, Separator, Breadcrumb, Layout},
  props: {
    title: {
      type: String,
      default: 'Cookie Policy'
    }
  },
}
</script>