<template>
    <!-- Start Google MAp -->
    <div class="google-map-style-1">
        <iframe src="https://www.google.com/maps/embed/v1/place?q=Baden-Württemberg,+Deutschland&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" width="600" height="550" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
    <!-- End Google MAp -->
</template>

<script>
    export default {
        name: 'GoogleMap'
    }
</script>