<template>
    <div class="row row--15 service-wrapper">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12"
             v-for="(service, index) in serviceList"
             :key="index">
            <div :class="`service ${serviceStyle} ${checkTextAlign}`"
                 data-aos="fade-up"
                 data-aos-delay="70">
                <div class="inner">
                    <div class="content">
                        <h4 class="title">
                            <router-link :to="service.details" v-html="service.title"/>
                        </h4>
                        <p class="description" v-html="service.description"/>
                    </div>
                    <div class="image">
                        <img :src="service.image" alt="Service Images"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ServiceFive',
        props: {
            serviceStyle: {
                type: String
            },
            textAlign: {
                type: String,
                default: 'left'
            }
        },
        data() {
            return {
                serviceList: [
                    {
                        image: require('@/assets/images/service/custom/person-using-laptop.webp'),
                        title: 'SEO Optimization',
                        description: 'Implementing strategic techniques to improve search engine rankings and increase online visibility for businesses.',
                        details: '/service-details/seo-optimization'
                    },
                    {
                        image: require('@/assets/images/service/custom/high-angle-hands-holding-paper.webp'),
                        title: 'UI/UX Design',
                        description: 'Crafting intuitive and visually appealing user interfaces to enhance user experiences and drive engagement.',
                        details: '/service-details/ui-ux-design'
                    },
                    {
                        image: require('@/assets/images/service/custom/creativity-ideas-design-thought-bubble-icon-concept.webp'),
                        title: 'Concept Design',
                        description: 'Bringing ideas to life through captivating concept arts, creating visual representations that inspire and captivate audiences.',
                        details: '/service-details/concept-design'
                    }
                ]
            }
        },
        computed: {
            checkTextAlign() {
                if (this.textAlign === 'left') return 'text-start';
                else if (this.textAlign === 'center') return 'text-center';
                else if (this.textAlign === 'end') return 'text-end';
                else return '';
            }
        }
    }
</script>