<template>
  <Layout header-class="header-left-align header-transparent" nav-alignment="left" :show-top-header="false" :footer-style="2" :show-footer="false">
    <Breadcrumb current-url="Imprint" :title="title"/>

    <!-- Start Accordion Area -->
    <div class="rn-section-gap">
      <div class="container">
        <div class="row row--40 align-items-center">
          <div class="col-lg-8 offset-lg-2 mt_md--40 mt_sm--40">
            <div class="imprint">
              <h4 class="title">Imprint</h4>
              <h6>Information according to § 5 TMG:</h6>
              <p>
                Nico Kempe<br>
                Am Park 8<br>
                70771 Leinfelden-Echterdingen<br>
                Germany
              </p>
              <h6>Contact</h6>
              <ul class="liststyle bullet mb-5">
                <li>Phone: +49 176 53795344</li>
                <li>Email: hello@chantalherzog.com</li>
              </ul>
              <h6>Responsible for the content according to § 55 Abs. 2 RStV:</h6>
              <p>
                Nico Kempe<br>
                Am Park 8<br>
                70771 Leinfelden-Echterdingen<br>
                Germany
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Accordion Area -->

    <Separator/>
  </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import Breadcrumb from '../../components/elements/breadcrumb/Breadcrumb'
import Separator from '../../components/elements/separator/Separator'
import Icon from '../../components/icon/Icon'

export default {
  name: 'ConceptDesignServiceDetails',
  components: {Icon, Separator, Breadcrumb, Layout},
  props: {
    title: {
      type: String,
      default: 'Imprint'
    }
  },
}
</script>