<template>
    <Layout header-class="header-left-align header-transparent" nav-alignment="left" :show-top-header="false" :footer-style="2" :show-footer="false">
        <!-- Start Slider area -->
      <div class="slider-area slider-style-2 height-850 bg_image" data-black-overlay="7"
           :style="{'background-image': `url(${require(`@/assets/images/custom/big-min.webp`)})`}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="inner text-left">
                            <h1 class="title" data-aos="fade-up" data-aos-delay="100">
                                Hi, I am <br/>
                                <span class="theme-gradient">Chantal Herzog!</span>
                            </h1>
                            <p class="description" data-aos="fade-up" data-aos-delay="150">
                              I am a talented designer from Stuttgart specializing in UI design, logo design, SEO optimization, and concept arts.
                              Let's create exceptional designs together that captivate users and elevate your brand.</p>
                            <div class="button-group mt--40 mt_sm--20" data-aos="fade-up" data-aos-delay="200">
                                <router-link class="btn-default btn-border" to="/contact">
                                    Click to hire me
                                </router-link>
                                <router-link class="btn-default btn-icon" to="/about">
                                    Learn about me
                                    <Icon name="arrow-right" size="20" icon-class="icon"/>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider area -->

        <!-- <AboutSix/> -->

        <div class="rwt-portfolio-area rn-section-gapBottom mt--100">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 mb--10">
                <SectionTitle
                    title="My Services"
                    :section-title-style="2"
                    data-aos="fade-up"
                />
              </div>
            </div>
            <ServiceFive
                service-style="gallery-style"
                text-align="left"
            />
          </div>
        </div>

        <div class="rwt-timeline-area rn-section-gapBottom">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <Timeline :timeline-data="timelineData" :timeline-style="4"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="rwt-portfolio-area rn-section-gapBottom">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mb--40">
                        <SectionTitle
                            title="My Pricing"
                            :section-title-style="2"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <Pricing :pricing-data="pricingData"/>
            </div>
        </div>

        <!-- Start Brand Area  -->
      <!--
        <div class="rwt-brand-area pb--90">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <Brand :brand-list="brandList" :brand-style="3"/>
                    </div>
                </div>
            </div>
        </div>
        -->
        <!-- End Brand Area  -->

        <!-- Start Elements Area  -->
        <div class="rwt-testimonial-area rn-section-gapBottom">
            <div class="container">
                <div class="row mb--20">
                    <div class="col-lg-12">
                        <SectionTitle
                            title="My Reviews"
                            :section-title-style="2"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-12"
                         v-for="(testimonial, index) in testimonialData"
                         :key="index">
                        <Testimonial :testimonial="testimonial" :testimonial-style="3"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Elements Area  -->

        <div class="contact-area rn-section-gapBottom">
            <div class="container">
                <div class="row mb--20">
                    <div class="col-lg-12">
                        <SectionTitle
                            title="Contact me"
                            :section-title-style="2"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row mt--40 row--15">
                  <div class="col-lg-7">
                    <ContactForm/>
                  </div>
                  <div class="col-lg-5 mt_md--30 mt_sm--30">
                    <GoogleMap/>
                  </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from '../../components/common/Layout'
    import Icon from '../../components/icon/Icon'
    import AboutSix from '../../components/elements/about/AboutSix'
    import Timeline from '../../components/elements/timeline/Timeline'
    import ServiceFive from '../../components/elements/service/ServiceFive'
    import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
    import Pricing from '../../components/elements/pricing/Pricing'
    import Brand from '../../components/elements/brand/Brand'
    import Testimonial from '../../components/elements/testimonial/Testimonial'
    import ContactForm from '../../components/elements/contact/ContactForm'
    import GoogleMap from "@/components/elements/contact/GoogleMap.vue";

    export default {
        name: 'Freelancer',
        components: {
          GoogleMap,
            ContactForm,
            Testimonial,
            Brand,
            Pricing,
            SectionTitle,
            ServiceFive,
            Timeline,
            AboutSix,
            Icon,
            Layout
        },
        data() {
            return {
                timelineData: [
                    {
                        id: '1',
                        title: 'Conceptualization',
                        description: 'Generating Ideas and Planning Project Execution.',
                    },
                    {
                        id: '2',
                        title: 'Design and Development',
                        description: 'Creating Visual Designs and Implementing Solutions.',
                    },
                    {
                        id: '3',
                        title: 'Review and Feedback',
                        description: 'Evaluating Progress and Incorporating Stakeholder Input.',
                    },
                    {
                        id: '4',
                        title: 'Completion and Delivery',
                        description: 'Finalizing Project and Delivering Final Results.',
                    },
                ],
                pricingData: [
                    {
                        title: 'SEO Optimization',
                        currency: '€',
                        price: '39',
                        subtitle: 'EUR per hour',
                        features: [
                            {title: 'Keyword analysis and research'},
                            {title: 'Content optimization'},
                            {title: 'Backlink analysis and building'},
                            {title: 'SEO reporting and analysis'},
                            {title: 'Strategic recommendations'},
                        ],
                        btn: {
                            text: 'Purchase Now',
                            link: '/contact'
                        }
                    },
                    {
                        title: 'UI/UX Design',
                        currency: '€',
                        price: '29',
                        subtitle: 'EUR per hour',
                        features: [
                            {title: 'User-centered design concepts'},
                            {title: 'Visually appealing designs'},
                            {title: 'Color palette and typography'},
                            {title: 'Responsive design for devices'},
                            {title: 'Interaction design'},
                        ],
                        btn: {
                            text: 'Purchase Now',
                            link: '/contact'
                        },
                        isActive: false
                    },
                    {
                        title: 'Concept Arts',
                        currency: '€',
                        price: '49',
                        subtitle: 'EUR per hour',
                        features: [
                            {title: 'Stunning concept illustrations'},
                            {title: 'Visual style directions'},
                            {title: 'Adapting to various styles/genres'},
                            {title: 'Scenes and environments'},
                            {title: 'High-quality print-ready files'},
                        ],
                        btn: {
                            text: 'Purchase Now',
                            link: '/contact'
                        }
                    }
                ],
                brandList: [
                    {
                        image: require(`@/assets/images/brand/brand-01.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-02.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-03.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-04.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-05.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-06.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-07.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-08.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-01.png`)
                    },

                ],
                testimonialData: [
                    {
                        image: 'company-dashboard-nico-kempe',
                        name: 'Nico Kempe',
                        designation: 'CEO @ Company Dashboard',
                        location: 'Stuttgart, Germany',
                        description: '“Working with her was amazing. Captured our brand essence and elevated user experience.„',
                    },
                    /* {
                        image: 'testimonial-02',
                        name: 'Alexander Wodarz',
                        designation: 'CEO @ StartyourServer',
                        location: 'Berlin, Germany',
                        description: '“Their UI/UX expertise was invaluable. Keen eye for detail and exceptional results. Very satisfied!„',
                    },
                    {
                        image: 'testimonial-03',
                        name: 'Nico Witzig',
                        designation: 'CEO @ envite',
                        location: 'Hanover, Germany',
                        description: '“Couldn\'t be happier with their concept arts. Creativity brought our vision to life. Highly recommended!„',
                    }, */
                ],
            }
        }
    }
</script>