<template>
    <form ref="form" class="contact-form-1" action="" @submit="sendEmail">

        <div class="form-group">
          <input
              type="text"
              id="from_subject"
              name="from_subject"
              placeholder="Subject"
              required
          />
        </div>

        <div class="form-group">
            <input
                type="text"
                id="from_name"
                name="from_name"
                placeholder="Your Name"
                required
            />
        </div>


        <div class="form-group">
            <input
                type="email"
                id="from_email"
                name="from_email"
                placeholder="Email Address"
                required
            />
        </div>

        <div class="form-group">
            <input
                type="tel"
                id="from_phone"
                name="from_phone"
                placeholder="Phone Number"
                required
            />
        </div>



        <div class="form-group">
                <textarea
                    name="message"
                    id="message"
                    placeholder="Your Message"
                    required>
                </textarea>
        </div>

        <div class="form-group">
            <button class="btn-default btn-large">Submit</button>
        </div>

        <div class="form-group" v-html="showResult ? result : null"/>
    </form>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
    name: 'ContactForm',
    data() {
        return {
            showResult: false,
            result: `<p class="success-message">Your Message has been successfully sent. I will contact you soon.</p>`
        }
    },
  methods: {
    sendEmail(event) {
      event.preventDefault();
      emailjs.sendForm(
          process.env.VUE_APP_SERVICE_ID,
          process.env.VUE_APP_CONTACT_TEMPLATE_ID,
          this.$refs.form,
          process.env.VUE_APP_PUBLIC_KEY
      )
          .then((result) => {
            this.showResult = true;
            this.result = `<p class="success-message">Your Message has been successfully sent. I will contact you soon.</p>`;
          }, (error) => {
            this.showResult = true;
            this.result = `<p class="error-message">There was an error sending your message: ${error.text}</p>`;
          });
    }
  }
}
</script>