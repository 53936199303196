<template>
    <Layout header-class="header-left-align header-transparent" nav-alignment="left" :show-top-header="false" :footer-style="0" :show-footer="false">
        <!-- Start Error-Area Area  -->
        <div class="error-area ptb--200 ptb_sm--60 ptb_md--80">
            <div class="container">
                <div class="row align-item-center">
                    <div class="col-lg-12">
                        <div class="error-inner">
                            <h1>404</h1>
                            <h2 class="title">Page Not Found</h2>
                            <p class="description">The page you are trying to access cannot be found. <br> Please check the URL or navigate back to our homepage.</p>
                            <div class="view-more-button"><a class="btn-default" href="/">Back to the start</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Error-Area Area  -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Icon from '../components/icon/Icon'

    export default {
        name: '404',
        components: {Icon, Layout}
    }
</script>